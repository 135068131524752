const routes = {
    // 系统权限管理
    // // 角色管理
    role: {
        path: "/role",
        name: "role",
        meta: { name: "menu" },
        component: () =>
            import("../views/backStage/menuSettings/roleMan.vue"),
    },
    // 用户管理
    user: {
        path: "/user",
        name: "user",
        component: () =>
            import("../views/backStage/menuSettings/userMan.vue"),
    },
    // 普通用户列表
    userLabel0: {
        path: "/userLabel0",
        name: "userLabel0",
        component: () =>
            import("../views/backStage/menuSettings/userLabel0.vue"),
    },
    // 专家账号管理
    userLabel1: {
        path: "/userLabel1",
        name: "userLabel1",
        component: () =>
            import("../views/backStage/menuSettings/userLabel1.vue"),
    },
    // 园区管理员
    userLabel2: {
        path: "/userLabel2",
        name: "userLabel2",
        component: () =>
            import("../views/backStage/menuSettings/userLabel2.vue"),
    },
     // 订单管理
    order: {
        path: "/order",
        name: "order",
        component: () =>
            import("../views/backStage/menuSettings/orderList.vue"),
    },
    // 联系我们
    contact: {
        path: "/contact",
        name: "contact",
        component: () =>
            import("../views/backStage/menuSettings/contact.vue"),
    },
    // 联系我们
    app: {
        path: "/app",
        name: "app",
        component: () =>
            import("../views/backStage/menuSettings/app.vue"),
    },
    // 菜单管理
    menu: {
        path: "/menu",
        name: "menu",
        meta: {name: "menu"},
        component: () =>
            import("../views/backStage/menuSettings/menu.vue"),
    },
    // // 操作日志
    operationLog: {
        path: "/operationLog",
        name: "operationLog",
        meta: { name: "menu" },
        component: () =>
            import("../views/backStage/menuSettings/operationLog.vue"),
    },
    // 登陆日志
    signLog: {
        path: "/signLog",
        name: "signLog",
        meta: { name: "menu" },
        component: () =>
            import("../views/backStage/menuSettings/signLog.vue"),
    },
    // 大屏管理
    screenTitle: {
        path: "/screenTitle",
        name: "screenTitle",
        meta: { name: "screenTitle" },
        component: () =>
            import("../views/backStage/menuSettings/screenTitle.vue"),
    },
    // modularOne
    'alarmRecord': { //报警记录 
        path: '/alarmRecord',
        name: 'alarmRecord',
        component: () =>
            import ('../views/backStage/modularOne/alarmRecord.vue'),
    },
    'alarmManagement': { //报警管理
        path: '/alarmManagement',
        name: 'alarmManagement',
        component: () =>
            import ('../views/backStage/modularOne/alarmManagement.vue'),
    },
    'disasterWarning': { //灾害预警
        path: '/disasterWarning',
        name: 'disasterWarning',
        component: () =>
            import ('../views/backStage/modularOne/disasterWarning.vue'),
    },
    'weatherForewarning': { //气象预警
        path: '/weatherForewarning',
        name: 'weatherForewarning',
        component: () =>
            import ('../views/backStage/modularOne/weatherForewarning.vue'),
    },
    'equipmentList': { //设备列表
        path: '/equipmentList',
        name: 'equipmentList',
        component: () =>
            import ('../views/backStage/modularOne/equipmentList.vue'),
    },
    //modularTwo
    'pestDevice': { //虫情设备管理
        path: '/pestDevice',
        name: 'pestDevice',
        component: () =>
            import ('../views/backStage/modularTwo/pestDevice.vue'),
    },
    'pestKowns': { //病虫害知识库
        path: '/pestKowns',
        name: 'pestKowns',
        component: () =>
            import ('../views/backStage/modularTwo/pestKowns.vue'),
    },
    'insectPrevent': { //虫情预防措施
        path: '/insectPrevent',
        name: 'insectPrevent',
        component: () =>
            import ('../views/backStage/insect/insectPrevent/list.vue'),
    },

    'captureRecord': { //虫情抓拍记录
        path: '/captureRecord',
        name: 'captureRecord',
        component: () =>
            import ('../views/backStage/modularTwo/captureRecord.vue'),
    },
    //modularThree
    'baseList': { //基地列表
        path: '/baseList',
        name: 'baseList',
        component: () =>
            import ('../views/backStage/modularThree/baseList.vue'),
    },
    'cameraList': { //摄像头管理
        path: '/cameraList',
        name: 'cameraList',
        component: () =>
            import ('../views/backStage/modularThree/cameraList.vue'),
    },
    'keyManage': { //密钥管理
        path: '/keyManage',
        name: 'keyManage',
        component: () =>
            import ('../views/backStage/modularThree/keyManage.vue'),
    },
    'pestToken': { //密钥管理
        path: '/pestToken',
        name: 'pestToken',
        component: () =>
            import ('../views/backStage/modularTwo/pestToken.vue'),
    },
    'gangControl': { //联动控制
        path: '/gangControl',
        name: 'gangControl',
        component: () =>
            //import ('../views/backStage/gangControl/index.vue'),
            import ('../views/backStage/gangControl/list.vue'),
    },
    'gangControlHistory': { //联动控制记录
        path: '/gangControlHistory',
        name: 'gangControlHistory',
        component: () =>
            import ('../views/backStage/gangControl/history.vue'),
    },
    'centerInfo': { //个人中心
        path: '/centerInfo',
        name: 'centerInfo',
        component: () =>
            import ('../views/backStage/centerInfo/index.vue'),
    },
    'tripartiteDevice': { //三方设备管理
        path: '/tripartiteDevice',
        name: 'tripartiteDevice',
        component: () =>
            import ('../views/backStage/jx/deviceList.vue'),
    },
    'tripartiteDeviceKeyManage': { //三方设备管理
        path: '/tripartiteDeviceKeyManage',
        name: 'tripartiteDeviceKeyManage',
        component: () =>
            import ('../views/backStage/jx/keyManage.vue'),
    },

    //modularFive
    'deviceManagement': { //设备管理
        path: '/deviceManagement',
        name: 'deviceManagement',
        component: () =>
            import ('../views/backStage/modularFive/deviceManagement.vue'),
    },
    'deviceSecond': { //设备管理
        path: '/deviceSecond',
        name: 'deviceSecond',
        component: () =>
            import ('../views/backStage/modularFive/deviceSecond.vue'),
    },
    'templateManagement': { //模板管理
        path: '/templateManagement',
        name: 'templateManagement',
        component: () =>
            import ('../views/backStage/modularFive/templateManagement.vue'),
    },
    'dataCenter': { //数据中心
        path: '/dataCenter',
        name: 'dataCenter',
        component: () =>
            import ('../views/backStage/modularFive/dataCenter.vue'),
    },
    'bigData': { //大数据分析
        path: '/bigData',
        name: 'bigData',
        component: () =>
            import ('../views/backStage/modularFive/bigData.vue'),
    },

    'historyData': { //历史数据
        path: "/historyData",
        name: "historyData",
        component: () =>
            import ("../views/backStage/modularFive/historyData.vue")
    },
    'jxhistoryData': { //历史数据
        path: "/jxhistoryData",
        name: "jxhistoryData",
        component: () =>
            import ("../views/backStage/jx/historyData_jx.vue")
    },
    //摄像头管理暂时没有创建文件加  six是水肥灌溉
    // modularSix
    'operationRecord': { //操作记录
        path: '/operationRecord',
        name: 'operationRecord',
        component: () =>
            import ('../views/backStage/modularSix/operationRecord.vue'),
    },
    'sensorManagement': { //传感器管理
        path: '/sensorManagement',
        name: 'sensorManagement',
        component: () =>
            import ('../views/backStage/modularSix/sensorManagement.vue'),
    },
    'scheduledTask': { //定时任务
        path: '/scheduledTask',
        name: 'scheduledTask',
        component: () =>
            import ('../views/backStage/modularSix/scheduledTask.vue'),
    },

    'waterTemplate': { //模板管理
        path: '/waterTemplate',
        name: 'waterTemplate',
        component: () =>
            import ('../views/backStage/modularSix/waterTemplate.vue'),
    },
    'waterFertilizer': { //水肥设备管理
        path: '/waterFertilizer',
        name: 'waterFertilizer',
        component: () =>
            import ('../views/backStage/modularSix/waterFertilizer.vue'),
    },
    'remote': { //远程控制台
        path: '/remote',
        name: 'remote',
        component: () =>
            import ('../views/backStage/modularSix/remote.vue'),
    },
    //newBack
    'waterDeviceList': { //继电器设备
        path: '/waterDeviceList',
        name: 'waterDeviceList',
        meta: { name: 'menu' },
        component: () =>
            import ('../views/backStage/newBack/waterDeviceList.vue'),
    },
    'waterDeviceCrol': { //继电器控制
        path: '/waterDeviceCrol',
        name: 'waterDeviceCrol',
        meta: { name: 'menu' },
        component: () =>
            import ('../views/backStage/newBack/waterDeviceCrol.vue'),
    },
    'configuration': { //组态列表
        path: '/configuration',
        name: 'configuration',
        meta: { name: 'menu' },
        component: () =>
            import ('../views/backStage/newBack/configuration.vue'),
    },
    'console': { //执行记录
        path: '/console',
        name: 'console',
        meta: { name: 'menu' },
        component: () =>
            import ('../views/backStage/newBack/console.vue'),
    },

    'productionCycle': { //生产周期配置
        path: '/productionCycle',
        name: 'productionCycle',
        component: () =>
            import ('../views/backStage/sourceModular/production/productionCycle.vue'),
    },
    'plantingBatch': { //种植批次
        path: '/plantingBatch',
        name: 'plantingBatch',
        component: () =>
            import ('../views/backStage/sourceModular/production/plantingBatch.vue'),
    },
    'farmingTask': { //农事任务
        path: '/farmingTask',
        name: 'farmingTask',
        component: () =>
            import ('../views/backStage/sourceModular/production/farmingTask.vue'),
    },
    'farmWork': { //农事任务新，不关联作物生长周期，不关联批次
        path: '/farmWork',
        name: 'farmWork',
        component: () =>
            import ('../views/backStage/suyuan/farmWork.vue'),
    },
    'inputType': { //投入品类型
        path: '/inputType',
        name: 'inputType',
        component: () =>
            import ('../views/backStage/input/inputType/list.vue'),
    },
    'inputBuy': { //购买记录
        path: '/inputBuy',
        name: 'inputBuy',
        component: () =>
            import ('../views/backStage/input/inputBuy/list.vue'),
    },
    'produceData': { //生产数据
        path: '/produceData',
        name: 'produceData',
        component: () =>
            import ('../views/backStage/input/produceData/list.vue'),
    },

    'inputStore': { //库存记录
        path: '/inputStore',
        name: 'inputStore',
        component: () =>
            import ('../views/backStage/input/inputStore/list.vue'),
    },
    'inputOut': { //库存记录
        path: '/inputOut',
        name: 'inputOut',
        component: () =>
            import ('../views/backStage/input/inputOut/list.vue'),
    },
    'harvestManage': { //采收记录
        path: '/harvestManage',
        name: 'harvestManage',
        component: () =>
            import ('../views/backStage/sourceModular/inputManag/harvestManage.vue'),
    },
// 鱼塘绑定直传设备
    'fishPond': {
        path: '/fishPond',
        name: 'fishPond',
        component: () =>
            import ('../views/backStage/newSourceModular/fishPond.vue'),
    },
    // 基地绑定直传设备
    'plotManagNew': {
        path: '/plotManagNew',
        name: 'plotManagNew',
        component: () =>
            import ('../views/backStage/newSourceModular/plotManagNew.vue'),
    },
    // 基地绑定jxapi设备
    'plotManageJx': {
        path: '/plotManageJx',
        name: 'plotManageJx',
        component: () =>
            import ('../views/backStage/jx/plotManageJx.vue'),
    },
    'plantManage': { //作物管理 -- 列表
        path: '/plantManage',
        name: 'plantManage',
        component: () =>
            import ('../views/backStage/newSourceModular/plantManage.vue'),
    },
    'usageRecordNew': { // 投入品使用-- 列表
        path: '/usageRecordNew',
        name: 'usageRecordNew',
        component: () =>
            import ('../views/backStage/newSourceModular/usageRecordNew.vue'),
    },
    'plantBatchNew': { // 种植批次-- 列表
        path: '/plantBatchNew',
        name: 'plantBatchNew',
        component: () =>
            import ('../views/backStage/newSourceModular/plantBatchNew.vue'),
    },
    'harvestManageNew': { // 采收管理-- 列表
        path: '/harvestManageNew',
        name: 'harvestManageNew',
        component: () =>
            import ('../views/backStage/newSourceModular/harvestManageNew.vue'),
    },
    'productStorage': { //产品入库 -- 列表
        path: '/productStorage',
        name: 'productStorage',
        component: () =>
            import ('../views/backStage/newSourceModular/productStorage.vue'),
    },
    'productDelivery': { //产品出库 -- 列表
        path: '/productDelivery',
        name: 'productDelivery',
        component: () =>
            import ('../views/backStage/newSourceModular/productDelivery.vue'),
    },
    'productInventory': { //产品库存 -- 列表
        path: '/productInventory',
        name: 'productInventory',
        component: () =>
            import ('../views/backStage/newSourceModular/productInventory.vue'),
    },
    //lvxiaowen
    // 政策法规
    'lawsAReg': {
        path: '/lawsAReg',
        name: 'lawsAReg',
        component: () =>
            import('../views/backStage/lawsAReg/list.vue'),
    },
    // 农产品类型
    'productType': {
        path: '/productType',
        name: 'productType',
        component: () =>
            import('../views/backStage/productType/list.vue'),
    },
    // 价格平台
    'pricePlatf': {
        path: '/pricePlatf',
        name: 'pricePlatf',
        component: () =>
            import('../views/backStage/pricePlatf/list.vue'),
    },
    // 价格列表
    'priceList': {
        path: '/priceList',
        name: 'priceList',
        component: () =>
            import('../views/backStage/priceList/list.vue'),
    },
// 专家信息
    'expertMess': {
        path: '/expertMess',
        name: 'expertMess',
        component: () =>
            import('../views/backStage/expert/expertMess/list.vue'),
    },
    //最近聊天
    'contactList': {
        path: '/contactList',
        name: 'contactList',
        component: () =>
            import('../views/backStage/expert/contactList/list.vue'),
    },
    // 鱼病知识
    'fishKnow': {
        path: '/fishKnow',
        name: 'fishKnow',
        component: () =>
            import('../views/backStage/fishKnow/list.vue'),
    },



    // wh
    'village': { //乡村介绍
        path: '/village',
        name: 'village',
        component: () =>
            import ('../views/backStage/modularWh/village.vue'),
    },
    'ruralOverview': { //乡村概况
        path: '/ruralOverview',
        name: 'ruralOverview',
        component: () =>
            import ('../views/backStage/modularWh/ruralOverview.vue'),
    },
    'baseInfo': { //溯源大屏概况
        path: '/baseInfo',
        name: 'baseInfo',
        component: () =>
            import ('../views/backStage/sourceTrace/baseInfo.vue'),
    },
    'ruralGovernance': { //乡村治理
        path: '/ruralGovernance',
        name: 'ruralGovernance',
        component: () =>
            import ('../views/backStage/modularWh/ruralGovernance.vue'),
    },
    'production': { //大屏产量曲线
        path: '/production',
        name: 'production',
        component: () =>
            import ('../views/backStage/sourceTrace/production.vue'),
    },
    'production2': { //规模、产量
        path: '/production2',
        name: 'production2',
        component: () =>
            import ('../views/backStage/sourceTrace/production2.vue'),
    },
    'networks': { //网络管理
        path: '/networks',
        name: 'networks',
        component: () =>
            import ('../views/backStage/modularWh/networks.vue'),
    },
    'VillagerInfo': { //村民信息
        path: '/VillagerInfo',
        name: 'VillagerInfo',
        component: () =>
            import ('../views/backStage/modularWh/VillagerInfo.vue'),
    },
    'interviewInfo': { //走访信息
        path: '/interviewInfo',
        name: 'interviewInfo',
        component: () =>
            import ('../views/backStage/modularWh/interviewInfo.vue'),
    },
    'accountSummary': { //信息总览
        path: '/accountSummary',
        name: 'accountSummary',
        component: () =>
            import ('../views/backStage/modularWh/accountSummary.vue'),
    },
    'expenditure': { //资金收支管理
        path: '/expenditure',
        name: 'expenditure',
        component: () =>
            import ('../views/backStage/modularWh/expenditure.vue'),
    },
    'assets': { //资产管理
        path: '/assets',
        name: 'assets',
        component: () =>
            import ('../views/backStage/modularWh/assets.vue'),
    },
    'resource': { //资源管理
        path: '/resource',
        name: 'resource',
        component: () =>
            import ('../views/backStage/modularWh/resource.vue'),
    },
    'environmental': { //环境管理
        path: '/environmental',
        name: 'environmental',
        component: () =>
            import ('../views/backStage/modularWh/environmental.vue'),
    },
    'saleAccount': { //销售曲线
        path: '/saleAccount',
        name: 'saleAccount',
        component: () =>
            import ('../views/backStage/modularWh/saleAccount.vue'),
    },
    'waterControl': { //水肥控制
        path: '/waterControl',
        name: 'waterControl',
        component: () =>
            import ('../views/backStage/modularWh/waterControl.vue'),
    },
    'partyOrganization': { // 党组织管理
        path: '/partyOrganization',
        name: 'partyOrganization',
        component: () =>
            import ('../views/backStage/modularWh/partyOrganization.vue'),
    },
    'partyMember': { // 党员信息
        path: '/partyMember',
        name: 'partyMember',
        component: () =>
            import ('../views/backStage/modularWh/partyMember.vue'),
    },
    'outstandingParty': { // 优秀党员
        path: '/outstandingParty',
        name: 'outstandingParty',
        component: () =>
            import ('../views/backStage/modularWh/outstandingParty.vue'),
    },
    'retirement': { // 荣誉管理
        path: '/retirement',
        name: 'retirement',
        component: () =>
            import ('../views/backStage/modularWh/retirement.vue'),
    },
    'activityInfo': { // 活动资讯
        path: '/activityInfo',
        name: 'activityInfo',
        component: () =>
            import ('../views/backStage/modularWh/activityInfo.vue'),
    },
    'machinery': { //农机管理
        path: '/machinery',
        name: 'machinery',
        component: () =>
            import ('../views/backStage/modularWh/machinery.vue'),
    },
    'machineryOper': { //农机作业管理
        path: '/machineryOper',
        name: 'machineryOper',
        component: () =>
            import ('../views/backStage/modularWh/machineryOper.vue'),
    },
    'KeyPopulation': { //重点人群管理
        path: '/KeyPopulation',
        name: 'KeyPopulation',
        component: () =>
            import ('../views/backStage/modularWh/KeyPopulation.vue'),
    },
    'captureRecordManagement': { //无人机抓拍记录
        path: '/captureRecordManagement',
        name: 'captureRecordManagement',
        component: () =>
            import ('../views/backStage/wurenji/captureRecordManagement.vue'),
    },
    'flightRecordManagement': { //无人机飞行记录
        path: '/flightRecordManagement',
        name: 'flightRecordManagement',
        component: () =>
            import ('../views/backStage/wurenji/flightRecordManagement.vue'),
    },
    'regulations': { //无人机管理
        path: '/regulations',
        name: 'regulations',
        component: () =>
            import ('../views/backStage/wurenji/regulations.vue'),
    },
    'RoadSectionInformationManagement': { //无人机路线管理
        path: '/RoadSectionInformationManagement',
        name: 'RoadSectionInformationManagement',
        component: () =>
            import ('../views/backStage/wurenji/RoadSectionInformationManagement.vue'),
    },
    'controlDevice': { //建大仁科控制设备
        path: '/controlDevice',
        name: 'controlDevice',
        component: () =>
            import ('../views/backStage/controlDevice/control.vue'),
    },
    'coldType': { //冷库产品类型
        path: '/coldType',
        name: 'coldType',
        component: () =>
            import ('../views/backStage/coldStorage/coldType/list.vue'),
    },
    'coldPlat': { //冷库管理
        path: '/coldPlat',
        name: 'coldPlat',
        component: () =>
            import ('../views/backStage/coldStorage/coldStorage/list.vue'),
    },
    'coldInput': { //冷库入库记录
        path: '/coldInput',
        name: 'coldInput',
        component: () =>
            import ('../views/backStage/coldStorage/input/list.vue'),
    },
    'coldOut': { //冷库出库记录
        path: '/coldOut',
        name: 'coldOut',
        component: () =>
            import ('../views/backStage/coldStorage/out/list.vue'),
    },
    'coldStore': { //冷库库存记录
        path: '/coldStore',
        name: 'coldStore',
        component: () =>
            import ('../views/backStage/coldStorage/coldStore/list.vue'),
    },
    'uriToken': { //秘钥管理
        path: '/uriToken',
        name: 'uriToken',
        component: () =>
            import ('../views/backStage/uriToken/list.vue'),
    },
    'company': { //溯源企业管理
        path: '/company',
        name: 'company',
        component: () =>
            import ('../views/backStage/suyuan/company/list.vue'),
    },
    'caishou': { //溯源采收管理
        path: '/caishou',
        name: 'caishou',
        component: () =>
            import ('../views/backStage/suyuan/caishou/list.vue'),
    },
    'ptglWH': { //威海 平台管理
        path: '/ptglWH',
        name: 'ptglWH',
        component: () =>
            import ('../views/backStage/pmWeihai/ptgl.vue'),
    },
    'rzglBZ': { //威海 日志管理
        path: '/rzglBZ',
        name: 'rzglBZ',
        component: () =>
            import ('../views/backStage/pmWeihai/rzgl_bzb.vue'),
    },

    'rzglCJ': { //威海 日志管理
        path: '/rzglCJ',
        name: 'rzglCJ',
        component: () =>
            import ('../views/backStage/pmWeihai/rzgl_cjb.vue'),
    },
    'xmglWH': { //威海 项目管理
        path: '/xmglWH',
        name: 'xmglWH',
        component: () =>
            import ('../views/backStage/pmWeihai/xmgl.vue'),
    },
    'exportWH': { //威海 台账管理
        path: '/exportWH',
        name: 'exportWH',
        component: () =>
            import ('../views/backStage/pmWeihai/export.vue'),
    },
    'timeCtrol': { //威海 台账管理
        path: '/timeCtrol',
        name: 'timeCtrol',
        component: () =>
            import ('../views/backStage/controlDevice/timeCtrol.vue'),
    },
    'goodsList': { //商品列表
        path: '/goodsList',
        name: 'goodsList',
        component: () =>
            import ('../views/backStage/goods/goodsList/list.vue'),
    },
    'zhuanli': { //专利列表
        path: '/zhuanli',
        name: 'zhuanli',
        component: () =>
            import ('../views/backStage/goods/zhuanli/list.vue'),
    },
    'chengguo': { //科研成果
        path: '/chengguo',
        name: 'chengguo',
        component: () =>
            import ('../views/backStage/goods/chengguo/list.vue'),
    },
    'gongchang': { //智慧工厂
        path: '/gongchang',
        name: 'gongchang',
        component: () =>
            import ('../views/backStage/goods/gongchang/list.vue'),
    },
    'chanyedsj': { //产业大数据
        path: '/chanyedsj',
        name: 'chanyedsj',
        component: () =>
            import ('../views/backStage/goods/chanyedsj/list.vue'),
    },
    // 生产计划
    'shengchan': {
        path: '/shengchan',
        name: 'shengchan',
        component: () =>
            import('../views/backStage/goods/shengchan/list.vue'),
    },
    'keyan': {
        path: '/keyan',
        name: 'keyan',
        component: () =>
            import('../views/backStage/goods/keyan/list.vue'),
    },
    'paihang': {
        path: '/paihang',
        name: 'paihang',
        component: () =>
            import('../views/backStage/goods/paihang/list.vue'),
    },
    404: {
        path: "*",
        name: "404",
        component: () =>
            import("../views/404.vue"),
    },
};
export default routes;